<template>
  <div>
    <div v-if="!userloading && user.login" class="text-right pt-2 basic">

      <v-row no-gutters align="center" justify="center" >
        <v-col cols="12">
          <v-card flat class="pa-4 ma-3">
            <v-sheet
              tile
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev();"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next();"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet>
              <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-more="false"
                event-overlap-mode="stack"
                :event-color="getEventColor"
                event-text-color="black"
                :day-format="getDayFormat"
                locale="ko-KR"
                @change="getEvents"
                @click:event="showEvent"
              ></v-calendar>
              <v-dialog v-model="graph_dialog" width="800" :fullscreen="mobile">
                <v-card class="ma-0 pa-0">
                  <v-toolbar dark color="#FBDE44FF">
                    <v-btn icon color="black" @click="graph_dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="black--text">{{ graph_pick_date }} 식단</v-toolbar-title>
                  </v-toolbar>

                  <v-card-text>
                    <template>
                      <v-col md="12" cols="12" v-for="(meal, id) in foods_refined" :key="id" >
                        <v-card flat class="ma-0" color="grey lighten-4">
                          <v-card-title class="pa-2 pt-0 pb-0 title">
                            <v-row dense>
                              <v-col cols="4">
                                {{ meal.name }}
                              </v-col>
                              <v-col cols="2" class="ma-0 mt-1 pa-0">
                                <div class="subtitle-2 mt-1">
                                  {{ meal.kcal.format() }}Kcal
                                </div>
                              </v-col>
                              <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                                <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #70AD47">
                                  {{ meal.carb.format() }}g
                                </div>
                              </v-col>
                              <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                                <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #4472C7">
                                  {{ meal.protein.format() }}g
                                </div>
                              </v-col>
                              <v-col cols="2" align="center" class="ma-0 mt-1  pa-0">
                                <div class="subtitle-2 mt-1" style="border-bottom: 2px solid #ED7D31">
                                  {{ meal.fat.format() }}g
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-row dense>
                            <v-col md="6" cols="12" v-for="(each, i) in meal.list" :key="i">
                              <v-card outlined class="elevation-0 pa-3 pt-1 pb-1 ma-3 mt-0 mb-0">
                                <v-row dense>
                                  <v-col cols="12" class="subtitle-2">
                                    <v-row dense>
                                      <v-col cols="12">
                                        {{ each.name }} x {{ each.amount }}
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" class="caption">{{ (Math.round(each.calory*each.amount*10)/10) }}Kcal</v-col>
                                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ (Math.round(each.carb*each.amount*10)/10) }}g</v-col>
                                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ (Math.round(each.protein*each.amount*10)/10) }}g</v-col>
                                  <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ (Math.round(each.fat*each.amount*10)/10) }}g</v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';
import { mapGetters } from 'vuex'

import NeedLogin from './NeedLogin';
import UserLoading from './UserLoading';


import '../../common/common.js';

export default {
  components: {
    NeedLogin,
    UserLoading,
  },
  props: {
    msg: String
  },
  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: '일 - 토', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: '월 - 일', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: '월 - 금', value: [1, 2, 3, 4, 5] },
      { text: '월, 수, 금', value: [1, 3, 5] },
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

    foods_refined: {
      breakfast: {
        name: '아침',
        list: [],
        kcal: 0,
        carb: 0,
        protein: 0,
        fat: 0,
      },
      lunch: {
        name: '점심',
        list: [],
        kcal: 0,
        carb: 0,
        protein: 0,
        fat: 0,
      },
      dinner: {
        name: '저녁',
        list: [],
        kcal: 0,
        carb: 0,
        protein: 0,
        fat: 0,
      },
      snack: {
        name: '간식',
        list: [],
        kcal: 0,
        carb: 0,
        protein: 0,
        fat: 0,
      },
    },

    graph_pick_date:"",
    graph_dialog: false,
  }),
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      userloading: "userloading",

    }),
  },
  methods: {
    getDayFormat: function(data) {
      return data.day;
    },
    showEvent: async function({ nativeEvent, event }) {

      if ( event.name == "식단 보기" ) {

        this.graph_pick_date = event.start;

        const id_token = await firebase.auth().currentUser.getIdToken();
        const food_history = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory/', {
          params: { email: this.user.data.email, date: this.graph_pick_date },
          headers: {'id_token': id_token},
        });

        if ( food_history.data.length > 0 ) {

          for ( let [key, meal] of Object.entries(this.foods_refined) ) {
            this.foods_refined[""+key+""].list = [];
            this.foods_refined[""+key+""].kcal = 0;
            this.foods_refined[""+key+""].carb = 0;
            this.foods_refined[""+key+""].protein = 0;
            this.foods_refined[""+key+""].fat = 0;
          }

          let arr = ['t', 'breakfast', 'lunch', 'dinner', 'snack'];

          for ( let each of food_history.data[0].foodlist ) {
            this.foods_refined[arr[each.meal]].list.push(each);
            this.foods_refined[arr[each.meal]].kcal += each.calory * each.amount;
            this.foods_refined[arr[each.meal]].carb += each.carb * each.amount;
            this.foods_refined[arr[each.meal]].protein += each.protein * each.amount;
            this.foods_refined[arr[each.meal]].fat += each.fat * each.amount;
            this.foods_refined[arr[each.meal]].kcal = Math.round(this.foods_refined[arr[each.meal]].kcal*10)/10;
            this.foods_refined[arr[each.meal]].carb = Math.round(this.foods_refined[arr[each.meal]].carb*10)/10;
            this.foods_refined[arr[each.meal]].protein = Math.round(this.foods_refined[arr[each.meal]].protein*10)/10;
            this.foods_refined[arr[each.meal]].fat = Math.round(this.foods_refined[arr[each.meal]].fat*10)/10;
          }

          //this.graph_pick_food_list = this.food_list[ori_this.graph_pick_date];
          this.graph_dialog = true;
        }
      }

    },
    getEvents: async function ({ start, end }) {

      const events = [];
      const id_token = await firebase.auth().currentUser.getIdToken();
      const food_history = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodhistory', {
        params: { email: this.user.data.email, date: start.date, range: 1, date2: end.date },
        headers: {'id_token': id_token},
      });

      for ( let each_day of food_history.data ) {
        if ( each_day.foodlist.length && each_day.date != "") {
          events.push({
            name: "식단 보기",
            start: each_day.date,
            end:  each_day.date,
            color: "yellow",
          });
        }
      }

      // 다이어트
      const diet_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/diet', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      for ( let each_diet of diet_.data ) {
        if ( each_diet.date !== null && each_diet.date != "") {
          events.push({
            name: "다이어트",
            start: each_diet.date,
            end:  each_diet.date_f,
            color: "blue",
          });
        }
      }

      // 치팅
      const cheat_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/cheating', {
        params: { email: this.user.data.email, date: start.date },
        headers: {'id_token': id_token},
      });

      for ( let each_cheat of cheat_.data ) {
        if ( each_cheat.date !== null && each_cheat.date != "") {
          events.push({
            name: "치팅데이",
            start: each_cheat.date,
            end:  each_cheat.date,
            color: "red",
          });
        }
      }

      // 유지어트
      const maintenance_ = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/maintain', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      for ( let each_maint of maintenance_.data ) {
        if ( each_maint.date !== null ) {
          events.push({
            name: "유지어트",
            start: each_maint.date,
            end:  each_maint.date,
            color: "green",
          });
        }
      }

      this.events = events;
    },
    getEventColor: function (event) {
      return event.color
    },

  }
}
</script>

<style scoped>

.v-application .text-right {
  text-align: left !important;
  font-weight: bold;
}
</style>
