<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="align-center justify-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-col cols="12" align=center>
              로그인 정보를 불러오고 있습니다.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

export default {
  props: {
    msg: String
  },
  data: () => ({
  }),
}
</script>

<style scoped>

</style>
